@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900,200italic,300italic,400italic,600italic,700italic,900italic);

/* Override UGG site */
#main {width: 100%; padding:0;}
.content-asset p {margin:0 auto;}
.breadcrumb {display:none;}

body{
  width: 100%;
}

.col{
  padding-right: 0 !important;
  width: 100%;
}

.row{
  width: 100%;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #fff !important;
  background-color: transparent !important;
  border-color: dimgray;
  background: #fff !important;
}

.btn-primary {
  color: #fff !important;
  --bs-btn-bg:  #fff !important;
  --bs-btn-border-color: 0 !important
}


.textBlock{
  padding-right: 10px;
}

.ImageCarousel::-webkit-scrollbar {
  display: none;
  padding-right: 0 !important;
}

.ImageCarousel{
  padding-right: 0 !important;

}



.DayPicker_transitionContainer {
  padding-bottom: 37px;
  box-sizing: content-box;
}

/* .carousel{
  height: 650px;
} */

/* Helpers */
/**************************/
.margin-top-10 {padding-top:10px;}
.margin-bot-10 {padding-bottom:10px;}

/* Typography */
/**************************/
#parallax-world-of-ugg h1 {font-family:'Oswald', sans-serif; font-size:24px; font-weight:400; text-transform: uppercase; color:black; padding:0; margin:0;}
#parallax-world-of-ugg h2 {font-family:'Oswald', sans-serif; font-size:70px; letter-spacing:10px; text-align:center; color:white; font-weight:400; text-transform:uppercase; z-index:10; opacity:.9;}
#parallax-world-of-ugg h3 {font-family:'Oswald', sans-serif;  line-height:0; font-weight:400; letter-spacing:8px; text-transform: uppercase; color:black;}
#parallax-world-of-ugg p {font-family:'Source Sans Pro', sans-serif; font-weight:400; font-size:16px; line-height:24px;}
.first-character {font-weight:400; float: left; font-size: 84px; line-height: 64px; padding-top: 4px; padding-right: 8px; padding-left: 3px; font-family: 'Source Sans Pro', sans-serif;}

.sc {color: #3b8595;}
.ny {color: #3d3c3a;}
.atw {color: #c48660;}

/* Section - Title */
/**************************/
#parallax-world-of-ugg .title {background: white; padding: 50px; margin:0 auto; text-align:center;}
#parallax-world-of-ugg .title h1 {font-size:35px; letter-spacing:8px;}

/* Section - Block */
/**************************/
#parallax-world-of-ugg .block {background: white; padding: 60px; width:1020px; margin:0 auto; text-align:justify;}
#parallax-world-of-ugg .block-gray {background: #f2f2f2;padding: 60px;}
#parallax-world-of-ugg .section-overlay-mask {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: black; opacity: 0.70;}

/* Section - Parallax */
/**************************/
#parallax-world-of-ugg .parallax-one {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('/public/images/cropped-22-1024x615.jpeg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: top center;}
#parallax-world-of-ugg .parallax-two {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('/public/images/unnamed-1-2.jpeg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: center center;}
#parallax-world-of-ugg .parallax-three {padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('/public/images/unnamed-1-2.jpeg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: center center;}

/* Extras */
/**************************/
#parallax-world-of-ugg .line-break {border-bottom:1px solid black; width: 150px; margin:0 auto;}

/* Media Queries */
/**************************/
@media screen and (max-width: 959px) and (min-width: 768px) {
  #parallax-world-of-ugg .block {padding: 40px; width:620px;}
}
@media screen and (max-width: 767px) {
  #parallax-world-of-ugg .block .textBlock {padding-left: 10px;}
  #parallax-world-of-ugg .block {padding: 30px 0px 30px 0px; width:100%;}
  #parallax-world-of-ugg h2 {font-size:30px;}
  #mobileCarousel {flex: auto;}
  #parallax-world-of-ugg .parallax-one, #parallax-world-of-ugg .parallax-two, #parallax-world-of-ugg .parallax-three {padding-top:100px; padding-bottom:100px;}
}
@media screen and (max-width: 479px) {
  .parallax-world-of-ugg .block {padding: 30px 15px; width:290px;}
}


@media screen and (max-width: 767px) {
  .mobileCarousel {flex: auto !important;}

  h3 {
    font-size: 32px !important;
  }
  
} 

