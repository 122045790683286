li {
	list-style: none;
}

ul{
	padding: 0 !important;
	margin: 0;
}

nav {
	/* overflow: hidden; */
	/* display:grid; */
	place-items:center;
}

nav ul {
	width: 100%;
	padding: 0;
}



nav ul li {
	border-bottom: solid #ffffff .05rem;
	position: relative;
	z-index: 10;
}

nav ul li .link{
	display: flex;
	padding: 4rem 0 2rem 0;
	z-index: 10;
	justify-content:space-between;
	width: 100%;
}

nav ul li .link span {
	display: block;
	font-weight: 500;
	z-index: 10;
	mix-blend-mode: difference;
	display: inline-block;
	position: relative;
	color: white;
	font-size: 1.5rem;
	text-transform: uppercase;
}
nav ul li .link > img {
	mix-blend-mode: difference;
	z-index: 10;
}

.onhover {
    opacity: 1 !important;
    transform: translate(-100%, -50% ) rotate(5deg) !important;
}

.onimghover{
    transform: scale(1, 1) !important;
}

.hover-reveal {
	position: absolute;
	width: 300px;
	height: 400px;
	top: 50%;
	left: 50%;
	pointer-events: none;
	transform: translate(-50%, -50%);
	overflow: hidden;
	opacity: 0;
	transform: scale(0.8);
	transition: all .5s ease-out;
}

.hover-reveal img {
	width: 75%;
	height: 80%;
	overflow: hidden;
	object-fit: cover;
	position: relative;
	transition: transform .4s ease-out;
}


@media (max-width: 768px) {

	nav ul li a span {
		font-size: 2.5rem;
	}
	nav ul li a > img {
		height: 18px;
	}
	.hover-reveal {
		width: 220px;
		height: 300px;
	}
}
